import React from "react";
import cookie from "react-cookies";
import Close from "../images/300/Icons-Web-Final-01-White.png";
import {LoginApi} from '../data/login'
export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
      this.setState({[e.target.name]: e.target.value});
  }

  onSubmit(e) {
    e.preventDefault();
    console.log(this.state);
    var bodyFormData = new FormData();
    bodyFormData.append("email", this.state.email);
    bodyFormData.append("password", this.state.password);
    LoginApi(bodyFormData).then(r => {
        if(r.status == 200) {
            cookie.save("token", r.data, { path: '/' });
            window.location.reload();
        }
        else {
            alert('Authentication error.')
        }
    }).catch(error => {
        alert('Authentication error.')
    })
  }
  render() {
    return (
      <div className="login">
        <div className="close" onClick={this.props.onClose}>
            <img src={Close} alt="Close"/>
        </div>
        <form onSubmit={this.onSubmit} autoComplete="new-password">
          <h3>Login</h3>
          <div>
            <input
              type="text"
              placeholder="Email"
              name="email"
              autoComplete="nope"
              onChange={this.onChange}
              required={true}
            />
          </div>
          <div>
            <input
              type="password"
              placeholder="Password"
              name="password"
              autoComplete="nope"
              onChange={this.onChange}
              required={true}
            />
          </div>
          <div className="row">
            <div className="column">
              <button type="submit">Login</button>
            </div>
            <div className="column text-right">
              <a href="#!" onClick={this.props.onRegister}>You do not have an account?</a>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
