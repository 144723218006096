import React, { Component } from "react";
import { Detailt } from "../data/furniture";
import Close from "../images/300/Icons-Web-Final-01-White.png";
import CloseBlack from "../images/300/Icons-Web-Final-01.png";
import cookie from "react-cookies";

import Login from "../components/login";
import Register from "../components/register";

export default class Project extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            return: this.props.match.params.return,
            furniture: this.props.match.params.furniture,
            data: null,
            title: "",
            loading: true,
            url: "",
            detailt: null,
            isLogin: false,
            login: false,
            register: false,
            loadingSpecSheet: false
        };
        this.toggleLogin = this.toggleLogin.bind(this);
        this.toggleRegister = this.toggleRegister.bind(this);

    }

    componentDidMount() {
        window.$(".app").css("background-color", "white");
        let isLogin = false;
        if (cookie.load("token") && cookie.load("token") !== "") {
            isLogin = true;
        }

        Detailt(this.state.id).then((r) => {
            if (r.status === 200) {
                this.setState({
                    loading: false,
                    url: r.data.url,
                    data: r.data.data,
                    title: r.data.title,
                    detailt: r.data.detailt,
                    isLogin: isLogin,
                });
            }
        });
    }

    toggleLogin() {
        let state = this.state;
        state.login = !state.login;
        state.register = false;
        this.setState(state);
        return false;
    }
    toggleRegister() {
        let state = this.state;
        state.register = !state.register;
        state.login = false;
        this.setState(state);
        return false;
    }
    signOut() {
        cookie.remove('token');
        window.location.reload();
    }


    generateSpecSheet() {
        let url = process.env.REACT_APP_API + "/api/furniture/download/create.php";
        let data = new FormData();
        data.append("furniture_id", this.state.id);
        data.append("token", cookie.load("token"));

        this.setState({loadingSpecSheet: true});

        fetch(url, {
            method: "POST",
            body: data,
        })
            .then(async (r) => {

                if (r.status === 200) {
                    window.open(process.env.REACT_APP_API + "/specsheet/" + await r.text(), "_blank");
                }

                if (r.status !== 200) {
                    alert("Error processing request.");
                }

                this.setState({loadingSpecSheet: false});

            })
            .catch((error) => {
                alert("Error processing request.");
                this.setState({loadingSpecSheet: false});
            });
    }

    render() {
        if (this.state.loading === true) return null;
        return (
            <div className="detailt show">
                {this.state.login ? <Login onRegister={this.toggleRegister} onClose={() => this.setState({ login: false })}></Login> : null}
                {this.state.register ? <Register onLogin={this.toggleLogin} onClose={() => this.setState({ register: false })}></Register> : null}
                <div
                    className="detailt_exit"
                    onClick={() =>
                        window.open("/furniture/" + this.state.furniture, "_self")
                    }
                >
                    <img
                        src={this.state.detailt.is_black === "1" ? CloseBlack : Close}
                        alt="Close"
                        onClick={() =>
                            window.open("/furniture/" + this.state.furniture, "_self")
                        }
                    />
                </div>
                <div className="detailt_content">
                    <div className="detail-full-screen">
                        {this.state.data &&
                            this.state.data.map((item, index) => {
                                let images_path = this.state.url + item.images;
                                if (index === 0) {
                                    return (
                                        <div
                                            className="header-full-screen"
                                            key={index}
                                            style={{
                                                backgroundImage: `url(${images_path})`,
                                                backgroundColor:
                                                    this.state.detailt.is_black === "1"
                                                        ? "white"
                                                        : "black",
                                            }}
                                        >
                                            <div className="header-full-screen_header">
                                                <h1
                                                    style={{
                                                        color:
                                                            this.state.detailt.is_black === "1"
                                                                ? "black"
                                                                : "white",
                                                    }}
                                                >
                                                    {this.state.title && this.state.title}
                                                </h1>
                                            </div>
                                            <div className="header-full-screen_action">
                                                <div
                                                    style={{
                                                        color:
                                                            this.state.detailt.is_black === "1"
                                                                ? "black"
                                                                : "white",
                                                    }}
                                                    className="action"
                                                >
                                                    MIAMI | LONDON
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                                if (index === 1) {
                                    return (
                                        <div key={index} className="item-full-screen first">
                                            <div
                                                className="item-full-screen-item"
                                                style={{
                                                    backgroundImage: `url(${images_path})`,
                                                    backgroundColor:
                                                        this.state.detailt.is_black === "1"
                                                            ? "white"
                                                            : "black",
                                                }}
                                            />
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div key={index} className="item-full-screen">
                                            <div
                                                className="item-full-screen-item"
                                                style={{
                                                    backgroundImage: `url(${images_path})`,
                                                    backgroundColor:
                                                        this.state.detailt.is_black === "1"
                                                            ? "white"
                                                            : "black",
                                                }}
                                            />
                                        </div>
                                    );
                                }
                            })}
                        <div className="item-full-screen h-auto">
                            <div className="item-full-screen-info">
                                <div className="item-full-screen-title">
                                    {this.state.detailt && this.state.detailt.title}
                                </div>

                                {/* Login */}
                                {
                                    this.state.isLogin === false ? (
                                        <div className="item-full-screen-body">
                                            <div className="container_2">
                                                <div className="item-full-screen-body-tem no-padding no-with">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td><span onClick={() => this.setState({ login: true })} style={{ cursor: 'pointer' }}>PRODUCT INFORMATION</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className="margin" onClick={() => this.setState({ login: true })} style={{ cursor: 'pointer' }}>SPEC SHEET</span></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {/* Start Title */}
                                                <div className="item-full-screen-body-tem item-end">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="4"><span>{this.state.detailt && this.state.detailt.title1}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="4"><span>{this.state.detailt && this.state.detailt.title2}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="4"><span>{this.state.detailt && this.state.detailt.title3}</span></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {/* End Title */}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="item-full-screen-body">

                                            <div className="container_2">
                                                {/* Start Size */}
                                                {
                                                    this.state.detailt && this.state.detailt.has_size === "1" ? (
                                                        <div className="item-full-screen-body-tem no-padding">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><span> {this.state.detailt && this.state.detailt.regular_sofa === "0" ? "CORNER" : "DIMENSIONS"} </span></td>
                                                                        <td><span>H{this.state.detailt && Math.round(this.state.detailt.size_y)}</span></td>
                                                                        <td><span>W{this.state.detailt && Math.round(this.state.detailt.size_x)}</span></td>
                                                                        <td><span>D{this.state.detailt && Math.round(this.state.detailt.size_z)}</span></td>
                                                                        <td><span>IN</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span>{this.state.detailt && this.state.detailt.bed_size}&nbsp;</span></td>
                                                                        <td><span>H{this.state.detailt && Math.round(this.state.detailt.size_y * 2.54)}</span></td>
                                                                        <td><span>W{this.state.detailt && Math.round(this.state.detailt.size_x * 2.54)}</span></td>
                                                                        <td><span>D{this.state.detailt && Math.round(this.state.detailt.size_z * 2.54)}</span></td>
                                                                        <td><span>CM</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span>{this.state.detailt && Math.round(this.state.detailt.com) > 0 ? "C.O.M." : ""}</span></td>
                                                                        <td colSpan="4"><span>{this.state.detailt && Math.round(this.state.detailt.com) > 0 ? Math.round(this.state.detailt.com) : ""} {this.state.detailt && Math.round(this.state.detailt.com) > 0 ? this.state.detailt.com_type : ""}</span></td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    ) : null
                                                }
                                                {/* End Size */}

                                                {/* Start Center */}
                                                {
                                                    this.state.detailt && this.state.detailt.has_center === "1" ? (
                                                        <div className="item-full-screen-body-tem line">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><span>CENTER</span></td>
                                                                        <td><span>H{this.state.detailt && Math.round(this.state.detailt.center_size_y)}</span></td>
                                                                        <td><span>W{this.state.detailt && Math.round(this.state.detailt.center_size_x)}</span></td>
                                                                        <td><span>D{this.state.detailt && Math.round(this.state.detailt.center_size_z)}</span></td>
                                                                        <td><span>IN</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span>&nbsp;</span></td>
                                                                        <td><span>H{this.state.detailt && Math.round(this.state.detailt.center_size_y * 2.54)}</span></td>
                                                                        <td><span>W{this.state.detailt && Math.round(this.state.detailt.center_size_x * 2.54)}</span></td>
                                                                        <td><span>D{this.state.detailt && Math.round(this.state.detailt.center_size_z * 2.54)}</span></td>
                                                                        <td><span>CM</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span>{this.state.detailt && Math.round(this.state.detailt.center_com) > 0 ? "C.O.M." : ""}</span></td>
                                                                        <td colSpan="4"><span>{this.state.detailt && Math.round(this.state.detailt.center_com) > 0 ? Math.round(this.state.detailt.center_com) : ""} {this.state.detailt && Math.round(this.state.detailt.center_com) > 0 ? this.state.detailt.center_com_type : ""}</span></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    ) : null
                                                }
                                                {/* End Center */}

                                                {/* Start Ottoman */}
                                                {
                                                    this.state.detailt && this.state.detailt.has_ottoman === "1" ? (
                                                        <div className="item-full-screen-body-tem line">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><span>OTTOMAN</span></td>
                                                                        <td><span>H{this.state.detailt && Math.round(this.state.detailt.ottoman_size_y)}</span></td>
                                                                        <td><span>W{this.state.detailt && Math.round(this.state.detailt.ottoman_size_x)}</span></td>
                                                                        <td><span>D{this.state.detailt && Math.round(this.state.detailt.ottoman_size_z)}</span></td>
                                                                        <td><span>IN</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span>&nbsp;</span></td>
                                                                        <td><span>H{this.state.detailt && Math.round(this.state.detailt.ottoman_size_y * 2.54)}</span></td>
                                                                        <td><span>W{this.state.detailt && Math.round(this.state.detailt.ottoman_size_x * 2.54)}</span></td>
                                                                        <td><span>D{this.state.detailt && Math.round(this.state.detailt.ottoman_size_z * 2.54)}</span></td>
                                                                        <td><span>CM</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span>{this.state.detailt && Math.round(this.state.detailt.ottoman_com) > 0 ? "C.O.M." : ""}</span></td>
                                                                        <td colSpan="4"><span>{this.state.detailt && Math.round(this.state.detailt.ottoman_com) > 0 ? Math.round(this.state.detailt.ottoman_com) : ""} {this.state.detailt && Math.round(this.state.detailt.ottoman_com) > 0 ? this.state.detailt.ottoman_com_type : ""}</span></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    ) : null
                                                }
                                                {/* End Ottoman */}

                                                {/* Start Double Center */}
                                                {
                                                    this.state.detailt && this.state.detailt.has_double_center === "1" ? (
                                                        <div className="item-full-screen-body-tem line">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><span>DOUBLE CENTER</span></td>
                                                                        <td><span>H{this.state.detailt && Math.round(this.state.detailt.double_center_size_y)}</span></td>
                                                                        <td><span>W{this.state.detailt && Math.round(this.state.detailt.double_center_size_x)}</span></td>
                                                                        <td><span>D{this.state.detailt && Math.round(this.state.detailt.double_center_size_z)}</span></td>
                                                                        <td><span>IN</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span>&nbsp;</span></td>
                                                                        <td><span>H{this.state.detailt && Math.round(this.state.detailt.double_center_size_y * 2.54)}</span></td>
                                                                        <td><span>W{this.state.detailt && Math.round(this.state.detailt.double_center_size_x * 2.54)}</span></td>
                                                                        <td><span>D{this.state.detailt && Math.round(this.state.detailt.double_center_size_z * 2.54)}</span></td>
                                                                        <td><span>CM</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span>{this.state.detailt && Math.round(this.state.detailt.double_center_com) > 0 ? "C.O.M." : ""}</span></td>
                                                                        <td colSpan="4"><span>{this.state.detailt && Math.round(this.state.detailt.double_center_com) > 0 ? Math.round(this.state.detailt.double_center_com) : ""} {this.state.detailt && Math.round(this.state.detailt.double_center_com) > 0 ? this.state.detailt.double_center_com_type : ""}</span></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    ) : null
                                                }
                                                {/* End Double Center */}

                                                {/* Start Chaises */}
                                                {
                                                    this.state.detailt && this.state.detailt.has_chaises === "1" ? (
                                                        <div className="item-full-screen-body-tem line">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><span>DOUBLE CENTER</span></td>
                                                                        <td><span>H{this.state.detailt && Math.round(this.state.detailt.chaises_size_y)}</span></td>
                                                                        <td><span>W{this.state.detailt && Math.round(this.state.detailt.chaises_size_x)}</span></td>
                                                                        <td><span>D{this.state.detailt && Math.round(this.state.detailt.chaises_size_z)}</span></td>
                                                                        <td><span>IN</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span>&nbsp;</span></td>
                                                                        <td><span>H{this.state.detailt && Math.round(this.state.detailt.chaises_size_y * 2.54)}</span></td>
                                                                        <td><span>W{this.state.detailt && Math.round(this.state.detailt.chaises_size_x * 2.54)}</span></td>
                                                                        <td><span>D{this.state.detailt && Math.round(this.state.detailt.chaises_size_z * 2.54)}</span></td>
                                                                        <td><span>CM</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span>{this.state.detailt && Math.round(this.state.detailt.chaises_com) > 0 ? "C.O.M." : ""}</span></td>
                                                                        <td colSpan="4"><span>{this.state.detailt && Math.round(this.state.detailt.chaises_com) > 0 ? Math.round(this.state.detailt.chaises_com) : ""} {this.state.detailt && Math.round(this.state.detailt.chaises_com) > 0 ? this.state.detailt.chaises_com_type : ""}</span></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    ) : null
                                                }
                                                {/* End Chaises */}

                                                {/* Start Title */}
                                                <div className="item-full-screen-body-tem item-end">
                                                    <div>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan="4"><span>{this.state.detailt && this.state.detailt.title1}</span></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="4"><span>{this.state.detailt && this.state.detailt.title2}</span></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="4"><span>{this.state.detailt && this.state.detailt.title3}</span></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {/* End Title */}
                                            </div>

                                            <div className="container_2">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <span className="margin" onClick={() => this.generateSpecSheet()} style={{ cursor: 'pointer' }}>{
                                                                    this.state.loadingSpecSheet ? "PLEASE WAIT...." : "SPEC SHEET"
                                                                }</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    )
                                }
                                {/* Login */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
