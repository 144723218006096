import React, { Component } from "react";
import Sidebar from "./menu";
import Footer from "./footer";
import Login from "./login";
import Register from "./register";
import cookie from "react-cookies";

/* Logos */
import WhiteLogo from "../images/CD-Icons-02-white.png";
export default class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      login: false,
      register: false,
    };
    this.listRef = React.createRef();
    this.menuRef = React.createRef();
    this.onActive = this.onActive.bind(this);
    this.noActive = this.noActive.bind(this);
    this.toggleLogin = this.toggleLogin.bind(this);
    this.toggleRegister = this.toggleRegister.bind(this);
    this.onLoginSubmit = this.onLoginSubmit.bind(this);
  }
  onActive() {
    document.body.classList.toggle("hidden");
    // Correct
    this.setState((state) => ({
      active: !state.active,
    }));
  }
  noActive() {
    document.body.classList.remove("hidden");
    // Correct
    this.setState((state) => ({
      active: false,
    }));
  }
  componentWillUnmount() {
    document.body.classList.toggle("hidden");
  }
  componentWillMount() {
    console.log('Valid User');
  }
  toggleLogin() {
    let state = this.state;
    state.login = !state.login;
    state.register = false;
    this.setState(state);
    return false;
  }
  toggleRegister() {
    let state = this.state;
    state.register = !state.register;
    state.login = false;
    this.setState(state);
    return false;
  }
  signOut() {
    cookie.remove('token', { path: '/' })
    window.location.reload();
  }
  onLoginSubmit() {

  }
  render() {
    return (
      <div className={"app " + (this.state.active ? "active" : "")}>
        {this.state.active && (
          <div
            className={
              "app_opacity " + (this.state.active ? "active" : "noactive")
            }
            onClick={this.noActive}
          ></div>
        )}
        <Sidebar active={this.state.active} onClick={this.noActive} onLogin={this.toggleLogin} onSignOut={this.signOut}/>
        {this.state.login ? <Login onRegister={this.toggleRegister} onClose={() => this.setState({login: false})}></Login> : null}
        {this.state.register ? <Register onLogin={this.toggleLogin} onClose={() => this.setState({register: false})}></Register> : null}
        <div
          className={"app_menu " + (this.state.active ? "active" : "")}
          onClick={this.onActive}
        >
          <span>
            <img src={WhiteLogo} alt="Logo" />
          </span>
        </div>
        {this.props.children}
        <Footer />
      </div>
    );
  }
}
