import React from "react";
import Close from "../images/300/Icons-Web-Final-01-White.png";
import { RegisterApi } from '../data/login'
import cookie from "react-cookies";
import { Profession } from "../data/profession";

export default class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false,
      phone: '',
      company: '',
      profession: '',
      name: '',
      address: '',
      city: '',
      state: '',
      zipcode: '',
      professions: []
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentDidMount() {
    Profession().then((r) => {
      console.log(r);
      if(r.status === 200) {
          this.setState({professions: r.data});
      }
    })
  }

  onSubmit(e) {
    e.preventDefault();
    console.log(this.state);
    var bodyFormData = new FormData();
    bodyFormData.append("email", this.state.email);
    bodyFormData.append("password", this.state.password);
    bodyFormData.append("name", this.state.name);
    bodyFormData.append("phone", this.state.phone);
    bodyFormData.append("company", this.state.company);
    bodyFormData.append("profession", this.state.profession);
    bodyFormData.append("address", this.state.address);
    bodyFormData.append("city", this.state.city);
    bodyFormData.append("state", this.state.state);
    bodyFormData.append("zipcode", this.state.zipcode);

    RegisterApi(bodyFormData).then(r => {
      if (r.status == 200) {
        cookie.save("token", r.data, { path: '/' });
        window.location.reload();
      }
      else {
        alert('Authentication error.')
      }
    }).catch(error => {
      alert('Authentication error.')
    })
  }
  render() {
    return (
      <div className="register">
        <div className="close" onClick={this.props.onClose}>
          <img src={Close} alt="Close" />
        </div>
        <form onSubmit={this.onSubmit} autoComplete="new-password">
          <h3>Register</h3>
          <div>
            <input
              type="text"
              placeholder="Name"
              name="name"
              autoComplete="nope"
              onChange={this.onChange}
              value={this.state.name}
              required={true}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Email"
              name="email"
              autoComplete="nope"
              onChange={this.onChange}
              value={this.state.email}
              required={true}
            />
          </div>
          <div>
            <input
              type="password"
              placeholder="Password"
              name="password"
              autoComplete="nope"
              onChange={this.onChange}
              value={this.state.password}
              required={true}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Phone Number"
              name="phone"
              autoComplete="nope"
              onChange={this.onChange}
              value={this.state.phone}
              required={true}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Company"
              name="company"
              autoComplete="nope"
              onChange={this.onChange}
              value={this.state.company}
              required={true}
            />
          </div>
          <div>
            <select 
              placeholder="Profession"
              name="profession"
              autoComplete="nope"
              onChange={this.onChange}
              value={this.state.profession}
              required={true}>
              <option value="">Profession</option>
              {this.state.professions && this.state.professions.map((item, index) => {
                  return (
                    <option value={item.services}>{item.services}</option>
                  )
              })}
            </select>
          </div>
          {/* start address */}
          <div>
            <input
              type="text"
              placeholder="Address"
              name="address"
              autoComplete="nope"
              onChange={this.onChange}
              value={this.state.address}
              required={true}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="City"
              name="city"
              autoComplete="nope"
              onChange={this.onChange}
              value={this.state.city}
              required={true}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="State"
              name="state"
              autoComplete="nope"
              onChange={this.onChange}
              value={this.state.state}
              required={true}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Zip Code"
              name="zipcode"
              autoComplete="nope"
              onChange={this.onChange}
              value={this.state.zipcode}
              required={true}
            />
          </div>
          {/* end address */}
          <div className="row">
            <div className="column">
              <button type="submit">Submit</button>
            </div>
            <div className="column text-right">
              <a href="#!" onClick={this.props.onLogin}>
                You have an account?
              </a>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
