import React, { Component } from "react";
import Loading from "../components/loading";
import { Furniture as Data } from "../data/furniture";
import Close from "../images/300/Icons-Web-Final-01.png";
function imagesLoaded() {
  const imgElements = document.querySelectorAll("img");
  for (const img of imgElements) {
    if (!img.complete) {
      return true;
    }
  }
  return true;
}
export default class Furniture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      data: null,
      title: "",
      loading: true,
      url: "",
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.isFull = true;
  }
  componentDidMount() {
    window.$("body").css("background-color", "black");
    window.$(".app").css("background-color", "white");
    Data(this.state.id).then((r) => {
      if (r.status === 200) {
        this.setState({
          data: r.data.data,
          url: r.data.url,
          title: r.data.title,
        });
      } else {
        window.open("/home", "_self");
      }
    });
  }
  handleImageChange() {
    let time = () =>
      setTimeout(() => {
        this.setState({
          loading: false,
        });
        window.$(".furniture").addClass("show");
        setTimeout(() => window.$(".furniture").removeClass("show"), 3000);
      }, 3000);
    if (imagesLoaded()) {
      time();
    } else {
      clearTimeout(time);
    }
  }
  render() {
    return (
      <div className="furniture">
        {this.state.loading && <Loading />}
        <div
          className="furniture_exit"
          onClick={() => window.open("/home/furniture", "_self")}
        >
          <img src={Close} alt="Close" />
        </div>
        <div className="furniture_content">
          <div className="furniture_header">
            <h1>{this.state.title}</h1>
          </div>
          <div className="container-fluid">
            <div className="row">
              {this.state.data &&
                this.state.data.map((item, index) => {
                  let border = false;
                  if (index % 2 === 0) {
                    border = true;
                  }
                  item.full = false;
                  if (index === this.state.data.length - 1 && index % 2 === 0) {
                    item.full = true;
                    border = false;
                  }
                  this.isFull = item.full;
                  return (
                    <div
                      className={
                        "p-0 m-0 col-sm-12 col-md-" +
                        (item.full ? "12" : "6") +
                        (border ? " furniture_border " : "")
                      }
                      key={index}
                      onClick={() =>
                        window.open(
                          "/furniture/detailt/" + this.state.id + "/" + item.id,
                          "_self"
                        )
                      }
                    >
                      <div className="furniture_item">
                        <div className="item_img">
                          <img
                            onLoad={this.handleImageChange}
                            src={this.state.url + item.images}
                            alt={index}
                          />
                        </div>
                        <div className="furniture_title">
                          <span>{item.title}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
